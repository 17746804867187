<template>
    <a-modal :title="null" :footer="null" :closable="false" :visible="isNewPeople && show">
        <div class="guide-wrap">
            <div class="page-1">
                <h4 class="guide-wrap__title">新人必读</h4>
                <div class="guide-wrap__link">
                    <p><a href="/help/article/1004.html" target="_blank">新手轻松过审：合格的文章怎么写</a></p>
                    <p><a href="/help/article/1008.html" target="_blank">如何查看收入 如何结算稿费</a></p>
                    <p><a href="/help/article/1010.html" target="_blank">有疑问点这里 寻求人工教学帮助</a></p>
                </div>  
            </div>
            <div class="guide-wrap__bottom">
                <a-button type="primary" size="large"  @click="markReading">我已学会，跳过</a-button>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
import localStore from 'storejs';
export default {
    data() {
        return {
            show: false,
        };
    },
    computed: {
        ...mapGetters({
            isNewPeople: "user/isNewPeople",
        }),
    },
    mounted() {
        // 5分钟内不显示
        let newPeopleTime = localStore('new_people_time') || '';
        if(parseInt((new Date().getTime()/1000) - newPeopleTime) > 60*5) {
            this.show = true;
        }
    },
    methods: {
        // 已读
        markReading() {
            this.show = false;
            localStore.set('new_people_time', parseInt(new Date().getTime()/1000));
        }
    },
};
</script>

<style lang="less" scoped>
.guide-wrap{
    text-align: center;
    font-size: 16px;
    padding-bottom: 80px;
    min-height: 300px;
    position: relative;
    b{
        color: #ff4d4f
    }
    &__title{
        font-size: 24px;
        margin-bottom: 20px;
    }
    &__link{
        a{
            color: #333;
            font-size: 16px;
            line-height: 1.7;
            margin-top: 20px;
            text-decoration: underline;
            &:hover{
                color: #34BC6E;
            }
        }
    }
    &__bottom{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        /deep/ .ant-btn{
            margin: 0 10px;
        }
    }
}
</style>
