<template>
    <a-card title="" :bordered="false">
        <a-table
            :columns="tableColumns"
            :data-source="currentList"
            size="middle"
            rowKey="id"
            :loading="tableLoading"
            :pagination="false"
            :scroll="{ y: tableHeight }"
            @change="tableChange"
        >
            <div slot="titleSlot" slot-scope="title, record">
                {{ title }}<a-tag style="margin-left: 5px" v-if="record.is_test" color="#fc9400">试稿</a-tag>
                <div v-if="title != record.origin_title" style="color: #999">原标题：{{ record.origin_title }}</div>
            </div>
            <div slot="statusSlot" slot-scope="status, record">
                <a-tag color="red" v-if="status == 70">快过期</a-tag>
                <a-tooltip v-if="status == 40" :title="record.other.creation.reason">
                    <a-tag color="red">待修改<a-icon type="question-circle"/></a-tag>
                </a-tooltip>
                <a-tag color="orange" v-if="status == 20">待投稿</a-tag>
                <a-tag color="green" v-if="status == 50">审核中</a-tag>
                <a-tag color="purple" v-if="status == 80">已过期</a-tag>
                <a-tag color="blue" v-if="status == 60 || status == 8 || status == 9 || status == 10 || status == 2"
                    >已完成</a-tag
                >
            </div>
            <div slot="deadlineTime" slot-scope="time">
                <div v-if="time > 0">{{ formatTime(time, "Y/M/D") }}<br />{{ formatTime(time, "H:I") }}</div>
                <div v-else>-</div>
            </div>
            <div class="table-actionbtn" slot="action" slot-scope="a, record">
                <a
                    :href="'/works/article/edit/' + record.id + '.html'"
                    v-if="record.status == 20 || record.status == 70 || record.status == 80"
                    @click.prevent="articleEdit(record.id)"
                >
                    <a-button type="primary" size="small">投稿</a-button>
                </a>
                <a
                    :href="'/works/article/edit/' + record.id + '.html'"
                    v-if="record.status == 40"
                    @click.prevent="articleEdit(record.id)"
                >
                    <a-button type="primary" size="small">修改</a-button>
                </a>
                <a
                    :href="'/works/article/edit/' + record.id + '.html'"
                    v-if="record.status == 50"
                    @click.prevent="articleEdit(record.id)"
                >
                    <a-button type="primary" size="small">编辑</a-button>
                </a>
                <a
                    :href="'/works/article/edit/' + record.id + '.html'"
                    v-if="[2, 8, 9, 10, 60].indexOf(record.status) >= 0"
                    @click.prevent="articleEdit(record.id)"
                >
                    <a-button size="small">查看</a-button>
                </a>
                <template v-if="backShow(record.deadline_time) && record.status == 20 && !record.is_test">
                    <a-button type="primary" size="small" v-if="record.status == 666" disabled>已退回</a-button>
                    <a-button
                        class="table-actionbtn__backbtn"
                        type="primary"
                        size="small"
                        v-else
                        :loading="backLoading.indexOf(record.id + '') >= 0"
                        @click="articleBack(record.id)"
                        >退回</a-button
                    >
                </template>
            </div>
        </a-table>
        <div class="table-bottom">
            <a-button type="primary" style="float: left" v-if="currentList.length" @click="copyAllTitle"
                >一键复制所有标题</a-button
            >
            <a-pagination
                v-model="pagination.current"
                :total="pagination.total"
                :default-page-size="pagination.limit"
                show-less-items
                @change="pageChange"
            />
        </div>
        <new-people />
        <a-modal
            destroy-on-close
            :wrap-class-name="articleWrite.first ? 'edit-first-modal-class' : ''"
            :mask="!articleWrite.first"
            :title="null"
            :footer="null"
            :width="1000"
            :mask-closable="false"
            centered
            v-model="articleWrite.show"
        >
            <article-edit-page v-if="!articleWrite.first" :article-id="articleWrite.id" @success="modelSuccess" />
        </a-modal>
    </a-card>
</template>
<script>
import { formatTime } from "@/common/date";
const tableHeight = window.innerHeight - (72 + 20 + 180);
import newPeople from "@/components/newPeople";
import articleEditPage from "./edit";
export default {
    components: {
        articleEditPage,
        newPeople,
    },
    data() {
        return {
            articleList: {},
            tableHeight,
            tableLoading: true,
            tableData: [],
            tableFiltersData: [], //表格筛选的数据
            // 投稿信息
            articleWrite: {
                first: true,
                show: false,
                id: null,
            },
            // 退回loading状态
            backLoading: [],
            // 分页配置
            pagination: {
                current: 1,
                limit: 20,
                total: 0,
            },
        };
    },
    computed: {
        // 当前分页列表
        currentList() {
            let thisPage = this.pagination.current;
            let thisData = this.articleList["page_" + thisPage];
            return thisData ? thisData : [];
        },
        // 表格
        tableColumns() {
            const list = this.currentList;
            const statusName = {
                10: "已完成", // 8、9、10、60发布过程都算是已完成，2归档
                20: "待审稿",
                40: "待修改",
                50: "审核中",
                70: "快过期",
                80: "已过期",
            };
            let statusArr = [],
                statusFilters = [];
            for (let i = 0, max = list.length; i < max; i++) {
                let status = list[i].status;
                if (status == 8 || status == 9 || status == 60) {
                    status = 10;
                }
                if (statusArr.indexOf(status) >= 0) continue;
                statusArr.push(status);
                statusFilters.push({
                    text: statusName[status],
                    value: status,
                });
            }
            return [
                {
                    title: "标题",
                    dataIndex: "title",
                    scopedSlots: { customRender: "titleSlot" },
                },
                {
                    title: "状态",
                    width: 100,
                    align: "center",
                    dataIndex: "status",
                    filters: statusFilters,
                    onFilter: (value, record) => {
                        return value == 10 ? [8, 9, 10, 60].indexOf(record.status) >= 0 : record.status == value;
                    },
                    scopedSlots: { customRender: "statusSlot" },
                },
                {
                    title: "要求简述",
                    dataIndex: "conditions",
                    scopedSlots: { customRender: "conditionsSlot" },
                },
                {
                    title: "截止时间",
                    width: 120,
                    align: "center",
                    dataIndex: "deadline_time",
                    scopedSlots: { customRender: "deadlineTime" },
                },
                {
                    title: "操作",
                    width: 160,
                    align: "center",
                    scopedSlots: { customRender: "action" },
                },
            ];
        },
    },
    created() {
        this.getArticleList();
    },
    mounted() {
        window.onresize = () => {
            this.tableHeight = window.innerHeight - (72 + 30 + 250);
        };
        // 处理编辑器不能正常渲染文本，
        // 先显示再隐藏，销毁Modal子元素，
        // 后面弹窗的才能正常渲染tinymce
        this.articleWrite.show = true;
        setTimeout(() => {
            this.articleWrite.show = false;
        });
        setTimeout(() => {
            this.articleWrite.first = false;
        }, 300);
    },
    destroyed() {
        // 取消退出监听事件
        window.onresize = null;
    },
    methods: {
        // 格式化时间
        formatTime(timestamp, format) {
            return formatTime(timestamp * 1000, format);
        },
        // 获取我的稿件
        getArticleList() {
            const page = this.pagination.current;
            this.$api
                .userManuscriptsGetlist({
                    page,
                    limit: this.pagination.limit,
                })
                .then((res) => {
                    if (res.status) {
                        let listData = {};
                        listData["page_" + page] = res.data.list;
                        this.articleList = Object.assign({}, this.martList, listData);
                        this.pagination.total = res.data.count;
                        this.tableFiltersData = [];
                    }
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },
        // 翻页
        pageChange(current) {
            if (!this.articleList["page_" + current]) {
                this.tableLoading = true;
                this.getArticleList();
            }
        },
        // 是否可以退回，大于3天
        backShow(time) {
            const day3 = 60 * 60 * 24 * 3;
            const thisTime = parseInt(new Date().getTime() / 1000);
            return time - thisTime >= day3;
        },
        // 稿件退回接稿中心
        articleBack(id) {
            this.backLoading = [id + ""];
            this.$api
                .userManuscriptsBack({
                    id,
                })
                .then((res) => {
                    // res = {status: 1}
                    if (res.status) {
                        this.$message.success({ content: "退稿成功", key: "messageKey" });
                        // 更新状态
                        let listData = this.articleList["page_" + this.pagination.current];
                        let changeData = {};
                        for (let i = 0, max = listData.length; i < max; i++) {
                            if (id == listData[i].id) {
                                changeData["a" + i] = Object.assign({}, listData[i], { status: 666 });
                            }
                        }
                        for (let k in changeData) {
                            this.articleList["page_" + this.pagination.current].splice(
                                k.replace("a", ""),
                                1,
                                changeData[k]
                            );
                        }
                    } else {
                        this.$message.warn({ content: res.msg, key: "messageKey" });
                    }
                })
                .finally(() => {
                    this.backLoading = [];
                });
        },
        // 稿件编辑,弹窗
        articleEdit(id) {
            this.articleWrite.id = id;
            this.articleWrite.show = true;
        },
        // 弹窗编辑成功
        modelSuccess(id) {
            const thisPage = this.pagination.current;
            const currentData = this.articleList["page_" + thisPage];
            currentData.forEach((item) => {
                if (item.id == id) {
                    item.status = 50;
                }
            });
            // 关闭弹窗
            this.articleWrite.show = false;
        },
        // 表格变化
        tableChange(pagination, filters, sorter, { currentDataSource }) {
            this.tableFiltersData = currentDataSource;
        },
        // 一键复制全部标题
        copyAllTitle() {
            const _this = this;
            let listData = this.tableFiltersData.length > 0 ? this.tableFiltersData : this.currentList;
            let titleStr = "";
            for (let i = 0, max = listData.length; i < max; i++) {
                titleStr += listData[i].title + "\n";
            }
            this.$copyText(titleStr).then(
                function(e) {
                    _this.$message.success({
                        content: "复制成功",
                    });
                },
                function(e) {
                    _this.$message.error({
                        content: "复制失败",
                    });
                }
            );
        },
    },
};
</script>
<style lang="less" scoped>
.table-actionbtn {
    /deep/ .ant-btn {
        margin: 0 4px;
    }
    &:empty:after {
        content: "--";
    }
    &__backbtn {
        background-color: #fc9400;
        border-color: #fc9400;
    }
}
.table-bottom {
    margin-top: 20px;
    text-align: right;
}
/deep/ .edit-first-modal-class {
    opacity: 0;
}
</style>
